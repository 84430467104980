import React from 'react'
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Masonry from 'react-masonry-css'
import {Container, Row } from "react-bootstrap"

import SpotlightArticle from "../components/SpotlightArticle"
import devSpotlight from "../media/img/development/dev-spotlight.jpg"
import Wiedenism from '../components/Wiedenism'

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1
}

export default function SomethingSomething(props) {
    // console.log(props)
    return (
        <Layout pageInfo={{ pageName: "something" }}>
        <SEO title="Library" />
        <SpotlightArticle tags={`Library`} title={` A compilation of cool stuff you should know about, interesting reads, and insightful articles. This page is regularly updated with new content so have a looksee every now and again.`} bgImg={devSpotlight} borderColor="#10f92b"/>
        <section>
      <Container>
        <Row>
          <div className="sorting--parent">
            <div className="left--item">{props.data.allIntranetPost.edges.length} Articles</div>
            {/* <div className="right--item">
              Sort by: Recently Added
            </div> */}
          </div>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="news--parent"
            columnClassName="news--parent--column"
          >
              {
                  props.data.allIntranetPost.edges.map(article => {
                    return (
                    <div className="news--content">
                        {article.node.type === `internal` && (
                            <Link to={`/library/${article.node.id__normalized}`}>
                                <div className="image">
                                    <img src={article.node.image} alt="" />
                                </div>
                                <div className="tag">
                                    Tag
                                </div>
                                <div className="header">
                                    {article.node.title}
                                </div>
                                <p className="excerpt muted">{article.node.excerpt}</p>
                                <small>Read More</small>
                            </Link>
                        )}
                        {article.node.type === `external` && (
                            <a href={article.node.link} target="_blank" rel="noreferrer">
                                <div className="image">
                                    <img src={article.node.image} alt="" />
                                </div>
                                <div className="tag">
                                    Tag
                                </div>
                                <div className="header">
                                    {article.node.title}
                                </div>
                                <p className="excerpt muted">{article.node.excerpt}</p>
                                <small>Go To Link</small>
                            </a>
                        )}
                    </div>
                  )})
              }
          </Masonry>
          </Row>

      </Container>

      <Wiedenism />
        {/* <div className="home__relative__links">
            <Container>
                <Row className="d-flex">
                    <Col>
                        <BorderLayout color={`#A8233D`}>
                            <div className="links__Wrap">
                                <RelatedArticleSingle title={`Who We Are`} slug={`/who-we-are`} />
                            </div>
                        </BorderLayout>
                    </Col>
                    <Col>
                        <BorderLayout color={`#30A2DE`}>
                            <div className="links__Wrap">
                                <RelatedArticleSingle title={`The Cheat Sheet`} slug={`/the-cheat-sheet`} />
                            </div>
                        </BorderLayout>
                    </Col>
                    <Col>
                        <BorderLayout color={`#8163E7`}>
                            <div className="links__Wrap">
                                <RelatedArticleSingle title={`Other Essentials`} slug={`/other-essentials`} />
                            </div>
                        </BorderLayout>
                    </Col> 
                    <Col>
                        <BorderLayout color={`#FFB100`}>
                            <div className="links__Wrap">
                                <RelatedArticleSingle title={`What's Happening`} slug={`/whats-happening`} />
                            </div>
                        </BorderLayout>
                    </Col>
                </Row>
            </Container>
        </div> */}
    </section>
        </Layout>
    )
}
export const query = graphql`
    query SomethingSomething {
        allIntranetPost(filter: {category: {eq: "Something + Something"}}) {
            edges {
                node {
                    id
                    id__normalized
                    title
                    image
                    type
                    category
                    excerpt
                    link
                    created
                }
            }
        }
    }
`